if(document.querySelector('.section--stageanimation')) {
    const stageAnimation = document.querySelector('.section--stageanimation')

    let checkPosition = () => {
        const positionTop = stageAnimation.getBoundingClientRect().top;

        console.log(positionTop)

        if (positionTop > 0) {
            stageAnimation.classList.add('scroll')
        }
        if (positionTop <= 0) {
            stageAnimation.classList.remove('scroll')
        }
    }

    window.addEventListener('scroll', () => {
        checkPosition();
    });
}
