import Swiper from "swiper";
import { Navigation, A11y } from "swiper";
import { initNeosEventItemCreated } from "../../Assets/Scripts/neosEvents";
import { initNeosEventItemSelected } from "../../Assets/Scripts/neosEvents";

export function projectSwiper() {
    document.querySelectorAll(".project--swiper").forEach((slider) => {
        const isNeosBackend = document.body.classList.contains('neos-backend')

        const swiper = new Swiper(slider, {
            modules: [Navigation, A11y],
            allowTouchMove: !isNeosBackend,
            slidesPerView: "auto",
            spaceBetween: 0,
            watchOverflow: true,
            resizeObserver: true,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            navigation: {
                nextEl: ".swiper-navigation-next",
                prevEl: ".swiper-navigation-prev",
            },
        });

        initNeosEventItemCreated(swiper, slider);
        initNeosEventItemSelected(swiper, slider);
    });
}
projectSwiper()
