import './Component/Slider/Slider.js'
import './Template/_Fragment/Footer/Navigation/FooterNavigation.js'
import './Template/_Fragment/StageAnimation/StageAnimation.js'

import { projectSwiper } from './Component/Slider/Slider.js'

document.addEventListener('Neos.NodeCreated', function(event) {
    const element = event.detail.element;

    if(element.querySelector('.project--swiper')) {
        projectSwiper()
    }
})
